<template>
  <div>
    <LeftDrawer />
    <v-system-bar v-if="isTheMainRole" app height="30" color="blue" dark class="custom-system-bar">
      <div class="text-center w--100 font-18">{{ watermarkKey }}</div>
    </v-system-bar>
    <AppBar @action-faq="actionFaq" />
    <v-main>
      <v-container fluid class="px-8 px-sm-10 pt-15 pb-20">
        <router-view />
      </v-container>
    </v-main>
    <WikiDrawer v-model="isShow" @close="actionFaq" />
  </div>
</template>

<script>
import LeftDrawer from '@/components/navigations/LeftDrawer'
import AppBar from '@/components/navigations/AppBar'
import WikiDrawer from '@/components/navigations/WikiDrawer.vue'
import {mapGetters} from 'vuex'

export default {
  main: 'MainLayout',
  components: {LeftDrawer, AppBar, WikiDrawer},
  data: () => ({
    isShow: false,
    watermarkKey: process.env.VUE_APP_WATERMARK_KEY,
  }),
  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      isManager: 'auth/isManager',
    }),
    isTheMainRole() {
      return this.isAdmin || this.isManager
    },
  },
  methods: {
    actionFaq() {
      this.isShow = !this.isShow
    },
  },
}
</script>
<style lang="scss">
.custom-system-bar {
  z-index: 15;
}
</style>
