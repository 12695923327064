export const DICTIONARY_TYPES = {
  basic: {},
  geo: {
    name: 'geoItems',
    url: 'geo-section/read-all',
    view: 'geo',
    withParams: true,
  },
  device: {
    name: 'deviceTypesWhiteItems',
    url: 'device-type/read-all',
    view: 'group',
    groupFiled: 'type',
    valueFiled: 'text',
    withParams: true,
    sort: 'type,asc',
  },
  iabWhite: {
    name: 'iabWhiteItems',
    url: 'iab-categories/read-all',
    view: 'iab',
    withParams: true,
  },
  domain: {
    name: 'domainWhiteItems',
  },
  carrier: {
    name: 'carrierWhiteItems',
    url: 'carrier/read-all',
    view: 'group',
    groupFiled: 'countryName',
    valueFiled: 'name',
    withParams: true,
    sort: 'countryName,asc',
  },
  model: {
    name: 'makeWhiteItems',
    url: 'device-make/read-all',
    view: 'model',
    withParams: true,
    // tabs: ['androidItems', 'iOSItems', 'otherItems'],
  },
  connection: {
    name: 'connectionTypesWhiteItems',
    url: 'connection-type/read-all',
    withParams: true,
  },
  osBlack: {
    name: 'osBlackItems',
    url: 'device-os/read-all',
    view: 'group',
    groupFiled: 'type',
    valueFiled: 'text',
    withParams: true,
    sort: 'type,asc',
  },
  iabBlack: {
    name: 'iabBlackItems',
    url: 'iab-categories/read-all',
    view: 'iab',
    withParams: true,
  },
  domainBlack: {
    name: 'domainBlackItems',
  },
  ipWhite: {
    name: 'ipAddressWhiteItems',
  },
  ipBlack: {
    name: 'ipAddressBlackItems',
  },
  safety: {
    name: 'safetyItems',
    url: 'brand-safety',
    view: 'safety',
  },
  page: {
    name: 'pageWhiteItems',
  },
  pageBlack: {
    name: 'pageBlackItems',
  },
  bundle: {
    name: 'bundleWhitelist',
  },
  bundleBlack: {
    name: 'bundleBlacklist',
  },
  browser: {
    name: 'browserWhitelist',
    url: 'browser/read-all',
    withParams: true,
    sort: 'name,asc',
  },
  language: {
    name: 'languageWhitelist',
    url: 'language/read-all',
    withParams: true,
  },
  browserBlack: {
    name: 'browserBlacklist',
    url: 'browser/read-all',
    withParams: true,
    sort: 'name,asc',
  },
  languageBlack: {
    name: 'languageBlacklist',
    url: 'language/read-all',
    withParams: true,
  },
}
