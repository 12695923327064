<template>
  <v-btn
    ref="copyButton"
    :width="width"
    :height="height"
    rounded
    icon
    :color="color"
    v-bind="$attrs"
    @click="copyToClipboard(url)"
    v-on="$listeners"
  >
    <v-icon :size="size">{{ isCopy ? 'mdi-check' : 'mdi-content-copy' }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'CopyButton',
  inheritAttrs: false,
  props: {
    url: {
      type: String,
      required: true,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
    width: {
      type: String,
      default: '34',
    },
    height: {
      type: String,
      default: '34',
    },
    size: {
      type: String,
      default: '16',
    },
  },
  data: () => ({
    isCopy: false,
  }),
  methods: {
    copyToClipboard(url) {
      const tempEl = document.createElement('input')
      tempEl.value = url
      this.$refs.copyButton.$el.appendChild(tempEl)
      tempEl.select()
      document.execCommand('copy')
      this.$refs.copyButton.$el.removeChild(tempEl)
      this.isCopy = true
      setTimeout(() => {
        this.isCopy = false
      }, 700)
    },
  },
}
</script>
