// TODO: поменять урлы на англ и проработать открывание деталей: поле type сделать массивом

export const WIKI_LINKS = [
  {
    key: 'dashboard',
    docs: [
      {
        name: 'docs.dashboardMain',
        url: 'статистика-по-кампаниям',
      },
    ],
  },
  {
    key: 'creative',
    docs: [
      {
        name: 'docs.creativeMain',
        url: 'креативы-с-чего-начать-2',
      },
      {
        name: 'docs.creativeDetailBanner',
        url: 'креатив-типа-баннер-создание',
        type: 'banner/new',
      },
      {
        name: 'docs.creativeDetailAudio',
        url: 'креатив-типа-аудио-создание',
        type: 'audio/new',
      },
      {
        name: 'docs.creativeDetailNative',
        url: 'нативный-креатив-создание',
        type: 'native/new',
      },
      {
        name: 'docs.creativeDetailVideo',
        url: 'креатив-типа-видео-создание',
        type: 'video/new',
      },
      {
        name: 'docs.creativeModeration',
        url: 'creative_moderation',
        type: 'creativeModeration',
      },
    ],
  },
  {
    key: 'target',
    docs: [
      {
        name: 'docs.targetMain',
        url: 'общая-информация',
      },
      {
        name: 'docs.targetDetail',
        url: 'category/targets',
        type: 'target/new',
      },
    ],
  },
  {
    key: 'rule',
    docs: [
      {
        name: 'docs.ruleMain',
        url: 'правила-с-чего-начать',
      },
      {
        name: 'docs.ruleDetailCost',
        url: 'правила-относительно-стоимости',
        type: 'cost/new',
      },
      {
        name: 'docs.ruleDetailTarget',
        url: 'правила-по-таргету',
        type: 'target/new',
      },
    ],
  },
  {
    key: 'finance',
    docs: [
      {
        name: 'docs.financeMain',
        url: 'управление-балансом',
      },
    ],
  },
  {
    key: 'campaign',
    docs: [
      {
        name: 'docs.campaignMain',
        url: 'кампания-с-чего-начать',
      },
      {
        name: 'docs.campaignCreate',
        url: 'создание-кампании',
        type: 'campaign/new',
      },
    ],
  },
]
